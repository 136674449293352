import React, {Fragment, useContext, useState, useEffect} from 'react'
import ComboBox from 'react-responsive-combo-box'
import Nav from '../components/ui/nav'
import Footer from '../components/landing/footer'
import { LoaderContext } from '../contexts/loader'
import { useCookies } from 'react-cookie'
import { decodeCookie } from '../utils/constants.js'

// Separated this into it's own component so it can receive context

let version = "0.0.0"
const currentEnv = process.env.GATSBY_ENV
if (typeof currentEnv !== "undefined"){
  // Pull in the version so we can display it
  version = require("../../package.json").version
}

let pollForResults = (timestamp, prefix) => {
  //console.log("pollForResults", timestamp)
  return new Promise((resolve, reject) => {
    let failTimemout = setTimeout(() => {
      // We waited but heard nothing, we can give up now
      failTimemout = null
      resolve({status: 'error', error: 'The server took too long to respond. Please try again later.'})
    }, 901000) // 15 minute timer

    let makeReq = () => {
      fetch(`https://paverops.s3.amazonaws.com/upload-status/stage${prefix}${timestamp}.json`).then(response => {
        // 403 indicates the file doesn't exist, we can keep waiting for it
        if (response.status === 200){
          // We got the file, continue fetch logic
          return response.json()
        } else {
          // If we didn't get the file, wait 2 seconds and try again
          if (failTimemout !== null){
            setTimeout(() => {
              makeReq()
            }, 2000)
          } else if (failTimemout !== null){
            // If modal closed, end the timeout
            clearTimeout(failTimemout)
          }
          return null
        }
      }).then((output) => {
        if (output){
          //console.log("output", output)
          clearTimeout(failTimemout)
          resolve(output)
        }
      })
    }
    // Kick off the requests
    makeReq()
  })
}

const exportTypes = [
  "File Geodatabase",
  "Shapefile",
  "GeoJson"
]

const AccountInner = ({lambdaUri}) => {
  let [layer, setLayer] = useState("")
  let [downloadType, setDownloadType] = useState("")
  let [featureCountObj, setFeatureCountObj] = useState({})
  let [downloadLink, setDownloadLink] = useState("")
  let [isDownloading, setIsDownloading] = useState(false)
  let [showDownloadSelect, setShowDownloadSelect] = useState(false)
  // Set modules from context
  const loaderContext = useContext(LoaderContext)
  const modules = loaderContext
  const editLayers = modules.editLayers
  //console.log("GOT MODULES", modules)

  const [cookies, setCookie, removeCookie] = useCookies(['paverReg'])
  // Safety check so we don't crash the app
  if (!cookies.paverReg){
    cookies.paverReg = {}
  } else {
    // Decode the result
    cookies.paverReg = decodeCookie(cookies.paverReg)
  }
  const paverReg = cookies.paverReg

  let accessibleLayers = Object.keys(editLayers)
  // If we want to limit what layers this org can create on, do that here
  if (paverReg.layers){
    // But make sure we only show layers that actually exist (it will be different between stage and prod, for example)
    // Also, exclude "Complete" layers from creation since it's based on a filter
    let newLayerSet = []
    for (let i = 0; i < Object.keys(editLayers).length; i++){
      if (
        (paverReg.layers.indexOf(Object.keys(editLayers)[i]) !== -1 || paverReg.layers.length === 0) && 
        editLayers[Object.keys(editLayers)[i]].title.toLowerCase().indexOf("complete") === -1 && 
        editLayers[Object.keys(editLayers)[i]].title.toLowerCase().indexOf("future") === -1){

        // All conditions are met, push this in
        newLayerSet.push(editLayers[Object.keys(editLayers)[i]].title)
      }
    }
    accessibleLayers = newLayerSet
  }
  //console.log("ACCESS", accessibleLayers)

  useEffect(() => {
    let promiseStack = []
    for (let layer in accessibleLayers){
      const thisLayer = editLayers[accessibleLayers[layer]]
      //console.log(thisLayer)
      const formData  = new FormData()
      formData.append("token", paverReg.token)
      formData.append("where", `MemberId='${paverReg.id}'`)
      formData.append("returnCountOnly", true)
      // Fetch count for this layer
      let newPromise = new Promise(resolve => {
        fetch(thisLayer.url+`/0/query?f=json`, {
          method: "POST",
          body: formData
        }).then(response => response.json()).then((output) => {
          //console.log("JUST OUTPUT", output)
          // If count is above 0, set the state
          if (output.count){
            setFeatureCountObj((prev) => {
              const tempObj = {}
              tempObj[accessibleLayers[layer]] = output.count
              return Object.assign({}, prev, tempObj)
            })
          }
          resolve()
        })
      })
      promiseStack.push(newPromise)
    }

    Promise.all(promiseStack).then(values => {
      setShowDownloadSelect(true)
    })
  }, [])

  const downloadCurrentData = async () => {
    // Exit early if we're already in progress
    if (isDownloading || !layer || !downloadType){
      //console.log("EXIT")
      return 
    }

    // Turn on the spinner here
    setIsDownloading(true)
    setDownloadLink("")

    // Get timestamp that we'll use for the rest of this function
    let timestamp = new Date().getTime()

    //console.log("DONWLOADING " + layer, editLayers[layer])
    // First, we need the feature service item ID
    let resp = await fetch(editLayers[layer].url+"/0/?f=json&token="+paverReg.token)
    //console.log("resp", resp)
    resp = await resp.json()
    const serviceId = resp.serviceItemId
    //console.log("resp", resp)

    // Let's export using the service ID
    let formData = new FormData()
    formData.append('serviceId', serviceId)
    formData.append('memberId', paverReg.id)
    formData.append('name', paverReg.fullname)
    formData.append('timestamp', timestamp)
    formData.append('layer', layer)
    formData.append('exportType', downloadType)
    formData.append('def', editLayers[layer].definitionExpression)
    resp = await fetch(lambdaUri+'/.netlify/functions/export-layer-background', {
      method: 'post',
      body: formData
    })
    if (resp.status === 202){
      // console.log("resp", resp)
      // console.log("export output", resp)
      resp = await pollForResults(timestamp, "export")

      // Now trigger the download
      if (resp.status === "ok"){
        // By setting the hidden iframe src, we prompt a download
        setDownloadLink(resp.result.download) 
      }
      setIsDownloading(false)
    }
  }

  //console.log("Object.keys(featureCountObj)", Object.keys(featureCountObj), accessibleLayers)
  
  return (
    <Fragment>
      <div className="non-landing">
        <Nav page={"support"} items={[
          {url: "/app/", style: "border", text: "App Home"},
          {url: "/support/", style: "border", text: "Support Hub"}
        ]} />
        <div className="topper">
          <div className="img-wrapper">
            <img src="/images/toppersupport.png" alt="An angular slice of a road being repaired with heavy machinery" />
          </div>
          <div className="deck-wrapper">
            <div className="deck">
              <h1>
                Account
              </h1>
            </div>
          </div>
        </div>
      </div>
      <section className="app-body support account">
        <div className="help-container">
          <h2>User information</h2>
          <div className="flex-table">
            <div className="flex-row">
              <label>User name</label>
              <p>{paverReg.fullname}</p>
            </div>
            <div className="flex-row">
              <label>User role</label>
              <p>{paverReg.role}</p>
            </div>
            <div className="flex-row">
              <label>ArcGIS email</label>
              <p>{paverReg.email}</p>
            </div>
            <div className="flex-row">
              <label>Organzation name</label>
              <p>{paverReg.org}</p>
            </div>
          </div>
          <h2>Organization statistics</h2>
          <div className="flex-row">
            <label>Feature count</label>
            {Object.keys(featureCountObj).map(key => {
              return <div className="feature-count" key={key}>
                <span>{key}</span>
                <span>{featureCountObj[key].toLocaleString()}</span>
              </div>
            })} 
          </div>
          {/* Handle the current data download */}
          {paverReg.dataaccess.indexOf("current") !== -1 &&
            <>
              <h2>Download organization data</h2>
              <div className="flex-table">
                <div className="support-form-wrapper">
                  <label htmlFor="support-reason">Select layer to download:</label>
                  <div className="field-box">
                    {showDownloadSelect &&
                      <ComboBox 
                        options={Object.keys(featureCountObj)}
                        editable={false}
                        onSelect={(option) => {
                          setLayer(option)
                        }}
                        defaultValue={layer}
                        className="combo"
                      />
                    }
                  </div>
                  <label htmlFor="support-reason">Select file type to download:</label>
                  <div className="field-box">
                    {showDownloadSelect &&
                      <ComboBox 
                        options={exportTypes}
                        editable={false}
                        onSelect={(option) => {
                          setDownloadType(option)
                        }}
                        defaultValue={""}
                        className="combo"
                      />
                    }
                  </div>
                </div>
                <div className="account-download">
                  <button className={(layer && downloadType) ? "primary" : "primary disabled"} onClick={downloadCurrentData}>
                    {isDownloading ? (
                      <span className="loading">
                        <img src="/loading.png" alt="Loading icon" />
                      </span>
                    ) : (
                      <span>Download</span>
                    )} 
                  </button>
                </div>
                <iframe src={downloadLink} style={{display: "none"}}>Download</iframe>
              </div>
            </>
          }
          {/* Handle the data backups */}
          {paverReg.dataaccess.indexOf("current + past") !== -1 &&
            <>
            </>
          }
        </div>
      </section>

      <label className="paverops-version">PaverOps version: {version}</label>
      <Footer />
    </Fragment>
  )
}

export default AccountInner